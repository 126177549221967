<template>
  <b-container>
    <b-row>
      <b-col cols="12">
        <ApexDonutChart />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import ApexDonutChart from "./ApexDonutChart.vue";
import { BContainer, BRow, BCol } from "bootstrap-vue";

export default {
  components: {
    BContainer,
    ApexDonutChart,
    BRow,

    BCol,
  },
  methods: {
    handleSdkInit({ FB, scope }) {
      this.FB = FB;
      this.scope = scope;
    },
    getPostFacebook() {
      let token = this.facebookToken;
      console.log(token);
      const url = process.env.VUE_APP_API_URL
      axios
        .get(
          url + "marketing/facebook/getpagepost?token=" + token
        )
        .then((res) => {
          this.pagePost = res.data.result.data;
          localStorage.setItem("pagePost", JSON.stringify(this.pagePost));
          console.log(pagePost);
        });
    },
    onLogin(response) {
      console.log(response);
      this.FB.api("/me/accounts", "GET", { fields: "name,access_token" }, (data) => {
        this.facebookToken = data.data[0].access_token;
        localStorage.setItem("facebookToken", this.facebookToken);
        console.log(data);

        if (this.facebookToken) {
          this.getPostFacebook();
        }
      });
      this.FB.api("/me", "GET", { fields: "name" }, (data) => {
        this.userId = data;
      });
    },
    getAppId() {
      let config = {
        headers: {
          Authorization: "Bearer " + this.token,
        },
      };
      const url = process.env.VUE_APP_API_URL
      axios
        .get(url + "marketing/facebook/requestappid", config)
        .then((res) => {
          this.appId = res.data.result.token;
        });
    },

    showToast() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `Welcome ${this.name}`,
          icon: "BellIcon",
          text: "👋 You have successfully logged in!",
          variant: "Success",
        },
      });
    },
  },
};
</script>
