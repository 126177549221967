<template>
  <div>
    <div class="facebook__login card p-3 text-center bg-info">
      <div class="text">
        <h3 class="text-white">Facebook Login</h3>
        <p class="text-white">Login with Facebook to view all your posts</p>
        <div id="app" class="d-flex justify-content-center">
          <v-facebook-login
            :app-id="appid[0].appId"
            v-model="model"
            @sdk-init="handleSdkInit"
            @login="onLogin"
          ></v-facebook-login>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center mb-1">
      <b-spinner
        variant="success"
        label="Spinning"
        block
        v-if="!connected"
        style="width: 3rem; height: 3rem"
      ></b-spinner>
    </div>
    <b-card v-for="item in report.data" :key="item.id">
      <b-card-title class="mb-1"> {{ item.title }}</b-card-title>
      <b-card-sub-title class="mb-2"> {{ item.description }} </b-card-sub-title>

      <vue-apex-charts
        height="350"
        type="line"
        :options="chartOptions(item.values)"
        :series="chartData(item.values)"
      />
    </b-card>

    <b-card v-for="item in reportPost.data" :key="item.id">
      <b-card-title class="mb-1"> {{ item.title }}</b-card-title>
      <b-card-sub-title class="mb-2"> {{ item.description }} </b-card-sub-title>

      <vue-apex-charts
        height="350"
        type="line"
        :options="chartOptions(item.values)"
        :series="chartData(item.values)"
      />
    </b-card>
    <b-card v-for="item in reportEngagement.data" :key="item.id">
      <b-card-title class="mb-1"> {{ item.title }}</b-card-title>
      <b-card-sub-title class="mb-2"> {{ item.description }} </b-card-sub-title>

      <vue-apex-charts
        height="350"
        type="line"
        :options="chartOptions(item.values)"
        :series="chartData(item.values)"
      />
    </b-card>
    <b-card v-for="item in pageContent.data" :key="item.id">
      <b-card-title class="mb-1"> {{ item.title }}</b-card-title>
      <b-card-sub-title class="mb-2"> {{ item.description }} </b-card-sub-title>

      <vue-apex-charts
        height="350"
        type="line"
        :options="chartOptions(item.values)"
        :series="chartData(item.values)"
      />
    </b-card>
  </div>
</template>

<script>
import { BCard, BCardTitle, BCardSubTitle, BSpinner } from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import VFacebookLogin from "vue-facebook-login-component";
import axios from "axios";
import { $themeColors } from "@themeConfig";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    BCard,
    VueApexCharts,
    BCardTitle,
    BCardSubTitle,
    VFacebookLogin,
    BSpinner,
  },
  data() {
    return {
      appId: "",
      name: "",
      email: "",
      personalID: "",
      picture: "",
      fb: null,
      message: "",
      pageid: "",
      FB: {},
      model: {},
      scope: {},
      facebookToken: "",
      report: [],
      reportPost: [],
      reportEngagement: [],
      userId: {},
      pageContent: [],
      connected: false,
      bearerToken: localStorage.getItem("accessToken"),
    };
  },
  computed: {
    ...mapGetters("marketingModule", {
      appid: "appid",
      loadingMarketing: "loading",
    }),
  },
  async mounted() {
    this.getAppIdAction();
  },


  methods: {
    ...mapActions("marketingModule", ["getAppIdAction"]),
    chartOptions(values) {
      return {
        xaxis: {
          type: "datetime",
          categories: values.map((entry) => entry.endTime),
          labels: {
            offsetY: 5,
            style: {
              colors: "#b9b9c3",
              fontSize: "0.857rem",
            },
          },
        },
        grid: {
          borderColor: "#ebe9f1",
          padding: {
            top: -20,
            bottom: 5,
            left: 20,
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            inverseColors: false,
            gradientToColors: [$themeColors.primary],
            shadeIntensity: 1,
            type: "horizontal",
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100, 100, 100],
          },
        },
        yaxis: {
          tickAmount: 5,
          labels: {
            style: {
              colors: "#b9b9c3",
              fontSize: "0.857rem",
            },
            formatter(val) {
              return val > 999 ? `${(val / 1000).toFixed(1)}k` : val;
            },
          },
        },
        yaxis: {
          labels: {
            offsetY: 5,
            style: {
              colors: "#b9b9c3",
              fontSize: "0.857rem",
            },
          },
        },
      };
    },
    chartData(values) {
      return [{ name: "Trend", data: values.map((entry) => entry.value_) }];
    },
    handleSdkInit({ FB, scope }) {
      this.FB = FB;
      this.scope = scope;
    },
    getPostFacebook() {
      let token = this.facebookToken;
      let access_token = localStorage.getItem("accessToken");
      console.log(token);
      axios.defaults.headers.common["Authorization"] = "Bearer " + access_token;
      const url = process.env.VUE_APP_API_URL
      axios
        .get(
         url + "marketing/facebook/getpagepostimpressions?token=" +
            token
        )
        .then((res) => {
          this.report = res.data;
          localStorage.setItem("report", JSON.stringify(this.report));
        });
    },
    getPostImpressions() {
      let token = this.facebookToken;
      console.log(token);
      let access_token = localStorage.getItem("accessToken");
      console.log(token);
      axios.defaults.headers.common["Authorization"] = "Bearer " + access_token;
      const url = process.env.VUE_APP_API_URL
      axios
        .get(
         url+ "marketing/facebook/getpageimpressions?token=" +
            token
        )
        .then((res) => {
          this.reportPost = res.data;
          localStorage.setItem("reportPost", JSON.stringify(this.reportPost));
        });
    },
    getContent() {
      let token = this.facebookToken;
      let access_token = localStorage.getItem("accessToken");
      console.log(token);
      axios.defaults.headers.common["Authorization"] = "Bearer " + access_token;
      console.log(token);
      const url = process.env.VUE_APP_API_URL
      axios
        .get(
          url + "marketing/facebook/getpagecontent?token=" + token
        )
        .then((res) => {
          this.pageContent = res.data;
          localStorage.setItem("pageContent", JSON.stringify(this.pageContent));
        });
    },
    getEngagement() {
      let token = this.facebookToken;
      let access_token = localStorage.getItem("accessToken");
      console.log(token);
      axios.defaults.headers.common["Authorization"] = "Bearer " + access_token;
      console.log(token);
      const url = process.env.VUE_APP_API_URL
      axios
        .get(
          url + "marketing/facebook/getpageengagement?token=" +
            token
        )
        .then((res) => {
          this.reportEngagement = res.data;
          localStorage.setItem("reportEngagement", JSON.stringify(this.reportEngagement));
        });
    },
    onLogin(response) {
      console.log(response);
      this.FB.api("/me/accounts", "GET", { fields: "name,access_token" }, (data) => {
        this.facebookToken = data.data[0].access_token;
        this.connected = true;
        localStorage.setItem("facebookToken", this.facebookToken);
        console.log(data);

        if (this.facebookToken) {
          this.getPostFacebook();
          this.getPostImpressions();
          this.getEngagement();
          this.getContent();
        }
      });
      this.FB.api("/me", "GET", { fields: "name" }, (data) => {
        this.userId = data;
      });
    },
    getAppId() {
      let config = {
        headers: {
          Authorization: "Bearer " + this.token,
        },
      };
      const url = process.env.VUE_APP_API_URL
      axios
        .get(url + "marketing/facebook/requestappid", config)
        .then((res) => {
          this.appId = res.data.result.token;
        });
    },

    showToast() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `Welcome ${this.name}`,
          icon: "BellIcon",
          text: "👋 You have successfully logged in!",
          variant: "Success",
        },
      });
    },
  },
};
</script>
